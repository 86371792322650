import logo from '@public/logos/logo-square-color.png';
import {
    AccumulativeShadows,
    Center,
    Decal,
    Environment,
    RandomizedLight,
    useGLTF,
    useTexture,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { state } from "@state/store";
import { easing } from "maath";
import { useRef } from "react";
import { useSnapshot } from "valtio";

const HomeCanvas = ({ position = [0, 0, 2.5], fov = 25 }) => (
    <Canvas
        shadows
        camera={{ position, fov }}
        gl={{ preserveDrawingBuffer: true }}
        eventSource={document.getElementById("root")}
        eventPrefix="client"
    >
        <ambientLight intensity={0.5} />
        <Environment files="/potsdamer_platz_1k.hdr" />
        <CameraRig>
            <Backdrop />
            <Center>
                <Shirt />
            </Center>
        </CameraRig>
    </Canvas>
);

function Backdrop() {
    const shadows = useRef();
    useFrame((state, delta) =>
        easing.dampC(
            shadows.current.getMesh().material.color,
            state.color,
            0.25,
            delta
        )
    );
    return (
        <AccumulativeShadows
            ref={shadows}
            temporal
            frames={60}
            alphaTest={0.85}
            scale={10}
            rotation={[Math.PI / 2, 0, 0]}
            position={[0, 0, -0.14]}
        >
            <RandomizedLight
                amount={4}
                radius={9}
                intensity={0.55}
                ambient={0.25}
                position={[5, 5, -10]}
            />
            <RandomizedLight
                amount={4}
                radius={5}
                intensity={0.25}
                ambient={0.55}
                position={[-5, 5, -9]}
            />
        </AccumulativeShadows>
    );
}

function CameraRig({ children }) {
    const group = useRef();
    const snap = useSnapshot(state);
    useFrame((state, delta) => {
        easing.damp3(
            state.camera.position,
            [snap.intro ? -state.viewport.width / 4 : 0, 0, 2],
            0.25,
            delta
        );
        easing.dampE(
            group.current.rotation,
            [state.pointer.y / 10, -state.pointer.x / 5, 0],
            0.25,
            delta
        );
    });
    return <group ref={group}>{children}</group>;
}

function Shirt(props) {
    const snap = useSnapshot(state);
    const logoTexture = useTexture(logo);
    const { nodes, scene, materials } = useGLTF("/shirt_baked_collapsed.glb");
    useFrame((state, delta) =>
        easing.dampC(materials.lambert1.color, snap.color, 0.25, delta)
    );
    return (
        <group>
            <mesh
                geometry={nodes.T_Shirt_male.geometry}
                material={materials.lambert1}
                position={[0, 0.04, 0.15]}
                rotation={[0, 5.7, 0]}
                scale={0.99}
            >
                <Decal
                    position={[0, 0.04, 0.15]}
                    rotation={[0, 0, 0]}
                    scale={0.20}
                    map={logoTexture}
                />
            </mesh>
        </group>
    );
}
export default HomeCanvas;
